import React from "react";
import { Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Map from "../googlemap/googlemap";
import YoutubePlayer from "../youtube";
import DisplayPdf from "../pdf";

import { ReactComponent as IconSpot } from "../../../assets/images/icon-spot.svg";
import { ReactComponent as IconDownload } from "../../../assets/images/icon-download.svg";
import { ReactComponent as IconInstagram } from "../../../assets/images/pages/roomdirectory/icon-instagram.svg";
import { ReactComponent as IconTripAdvisor } from "../../../assets/images/pages/roomdirectory/icon-tripadvisor.svg";
import { ReactComponent as IconFacebook } from "../../../assets/images/pages/roomdirectory/icon-facebook.svg";
import { ReactComponent as IconYoutube } from "../../../assets/images/pages/roomdirectory/icon-youtube.svg";
import { ReactComponent as IconGoogle } from '../../../assets/images/pages/roomdirectory/icon-google.svg'
import { ReactComponent as IconLinkedIn } from '../../../assets/images/pages/roomdirectory/icon-linkedin-circled.svg'
import { ReactComponent as IconWebsite } from '../../../assets/images/pages/roomdirectory/icon-webglobe.svg';

////** Modules types
const TextArea = (props) => {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: props.params.value }} />
    </>
  );
};

const Table = (props) => {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: props.params.value }} />
    </>
  );
};

const Image = (props) => {
  return (
    <>
      <figure>
        <img
          src={props.params.value}
          alt={props.params.label ? props.params.label : "image"}
        />
      </figure>
      {props.params.label ? (
        <small>
          <i>{props.params.label} </i>
        </small>
      ) : null}
    </>
  );
};

const Video = (props) => {
  return (
    <>
      <YoutubePlayer url={props.params.value}/>
    </>
  );
};

const Links = (props) => {
  return (
    <>
      <Link to={`../${props.slug}/services/${props.params.value}`}>
        <Button className="AddBtn">{props.params.label}</Button>
      </Link>
    </>
  );
};

const Maps = (props) => {

  let formatString = (string) => {
    let newString = string.replaceAll(" ", "+")
    return newString
  }
  formatString(props.params.address)

  const googlemap_link = `https://maps.google.com/?q=${formatString(props.params.address)}+${formatString(props.params.zipcode)}+${formatString(props.params.city)}`
  const hoster = {
    hosterName: props.params.value,
    hosterLat: props.params.latitude,
    hosterLong: props.params.longitude
  }

  return (
    <>

      <Map
        hoster={hoster}
        googlemap_link={googlemap_link}
      />
      <label>
        <IconSpot /> {props.params.address} - {props.params.postalcode}{" "}
        {props.params.city} {props.params.country}{" "}
      </label>
    </>
  );
};

const File = (props) => {
  return (
    <>
      {props.params.displayInside === true ? (
        <DisplayPdf
          open={true}
          file={props.params.value}
          // onClose={() => this.setState({ pdfModalIsOpen: false })}
          // toggle={this.toggleModal}
          footer={<div>{props.params.label}</div>}
          title={props.params.label}
        />
      ) : (
        <a href={props.params.value} target="_blank" rel="noreferrer" download>
          <button className="AddBtn btn btn-secondary">
            <IconDownload />
            {props.params.label}
          </button>
        </a>
      )}
    </>
  );
};

const SocialMedias = (props) => {
  return (
    <>
   <h3>Restons connectés</h3>
      <Row className="socialmedias">
        {props.params.instagram ? (
          <a href={props.params.instagram} target="_blank" rel="noreferrer">
            <button>
              <IconInstagram />
            </button>
          </a>
        ) : null}
        {props.params.facebook ? (
          <a href={props.params.facebook} target="_blank" rel="noreferrer">
            <button>
              <IconFacebook />
            </button>
          </a>
        ) : null}
        {props.params.tripadvisor ? (
          <a href={props.params.tripadvisor} target="_blank" rel="noreferrer">
            <button>
              <IconTripAdvisor />
            </button>
          </a>
        ) : null}
        {props.params.youtube ? (
          <a href={props.params.youtube} target="_blank" rel="noreferrer">
            <button>
              <IconYoutube />
            </button>
          </a>
        ) : null}
        {props.params.google ? (
          <a href={props.params.google} target="_blank" rel="noreferrer">
            <button>
              <IconGoogle />
            </button>
          </a>
        ) : null}
        {props.params.linkedIn ? (
          <a href={props.params.linkedIn} target="_blank" rel="noreferrer">
            <button>
              <IconLinkedIn />
            </button>
          </a>
        ) : null}
        {props.params.website ? (
          <a href={props.params.website} target="_blank" rel="noreferrer">
            <button>
              <IconWebsite />
            </button>
          </a>
        ) : null}
      </Row>
    </>
  );
};

class Module extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type,
      options: props.options,
      slug: props.slug,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type !== this.state.type) {
      this.setState({ type: nextProps.type });
    }
    if (nextProps.options !== this.state.options) {
      this.setState({ options: nextProps.options });
    }
  }

  moduleType(type, options) {
    switch (type) {
      case "text":
        return <TextArea params={options} />;
      case "table":
        return <Table params={options} />;
      case "image":
        return <Image params={options} />;
      case "video":
        return <Video params={options} />;
      case "link":
        return <Links params={options} slug={this.state.slug} />;
      case "map":
        return <Maps params={options} />;
      case "file":
        return <File params={options} />;
      case "socialmedia":
        return <SocialMedias params={options} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <>
        {this.state.options && this.state.type ? (
          <Row className="w-100 _module">
            {this.moduleType(
              this.state.type,
              this.state.options
            )}
          </Row>
        ) : null}
      </>
    );
  }
}

export default Module;
